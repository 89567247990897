import { useEffect, useState } from 'react';
import './App.css';
import logo from './Logo_1_1.png';
import imagem1 from './imagem_1.png';
import imagemTeste from './imagem_teste.jpeg';
import imagem2 from './imagem_2.png';
import imagem3 from './imagem_3.png';
import { Transition } from '@headlessui/react';
import { PiInstagramLogoBold, PiWhatsappLogoBold } from 'react-icons/pi';
import { FaUserTie } from 'react-icons/fa';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaHandshakeSimple } from 'react-icons/fa6';
import { MdOutlineMailOutline } from 'react-icons/md';

function App() {
  return (
    <div className=' justify-center items-center flex h-screen'>
      <img src={logo} alt='Logo Alessandra Resende' className='w-[300px]' />
    </div>
    // <div className='grid grid-rows-[120px_auto] h-screen'>
    //   <div className='h-[120px] z-50'>
    //     <div className='w-full h-[120px] shadow-2xl flex justify-center items-center overflow-auto'>
    //       <div className='w-[1366px] h-full flex items-center'>
    //         <img className={'w-[200px]'} src={logo} alt={'logo'} />
    //         <ul className='ml-auto flex gap-10 uppercase'>
    //           <li>Início</li>
    //           <li>Sobre a Ale</li>
    //           <li>Experiência</li>
    //           <li>Contato</li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='h-full overflow-auto flex flex-col z-30'>
    //     <div>
    //       <div className='w-full flex flex-col justify-center'>
    //         <div className='w-full bg-gradient-to-b from-[#F0D4EA] to-transparent grid place-items-center z-20 '>
    //           <img
    //             className='transition-opacity ease-in duration-700 opacity-60 object-cover w-[1366px] h-[500px]'
    //             src={imagemTeste}
    //             alt={'iamgem'}
    //           />

    //           <div className='w-[1366px] text-center bg-[#F0D4EA] flex justify-center items-center h-[100px] gap-40 opacity-100 rounded-b-2xl'>
    //             <div className='grid place-items-center text-[#433441] '>
    //               <FaUserTie className='text-4xl' />
    //               <div className='text-lg opacity-80'>Profissionalismo</div>
    //             </div>
    //             <div className='grid place-items-center text-[#433441] '>
    //               <FaHandHoldingHeart className='text-4xl' />
    //               <div className='text-lg opacity-80'>Repsonsabilidade</div>
    //             </div>
    //             <div className='grid place-items-center text-[#433441] '>
    //               <FaUsers className='text-4xl' />
    //               <div className='text-lg opacity-80'>Inclusão</div>
    //             </div>
    //             <div className='grid place-items-center text-[#433441] '>
    //               <FaHandshakeSimple className='text-4xl' />
    //               <div className='text-lg opacity-80'>Confiança</div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex justify-center z-30'>
    //           <div className='w-[1366px]'>
    //             <div className='float-right bg-[#F0D4EA] rounded-l-full p-10 text-2xl -mt-[240px] shadow-lg text-[#433441] w-[400px] flex justify-center z-50'>
    //               Atendimento Psicopedagógico
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='w-full flex justify-center bg-gradient-to-b from-transparent via-[#F0D4EA] to-transparent items-center h-[700px]'>
    //         <div className='w-[1366px] grid grid-cols-2'>
    //           <div className='flex flex-col gap-10'>
    //             <div className='text-4xl'>Sobre a Ale</div>
    //             <div className=''>
    //               Sou uma criatura da curiosidade e da inquietude, uma mente
    //               sempre faminta por conhecimento e experiência. Desde cedo, fui
    //               cativado pela beleza da diversidade do mundo ao meu redor e
    //               pela infinita complexidade da existência. Nasci em um pequeno
    //               vilarejo onde os dias eram medidos pela passagem do sol e as
    //               noites eram um espetáculo de estrelas cintilantes no céu
    //               negro. Foi lá que minhas primeiras aventuras começaram,
    //               explorando os campos verdejantes e as florestas sombrias que
    //               cercavam nosso lar. À medida que cresci, minha paixão pelo
    //               desconhecido só se intensificou. Devorei livros e absorvi cada
    //               pedacinho de informação que pude encontrar, sedento por
    //               desvendar os mistérios do universo e da mente humana. Cada
    //               nova descoberta era como uma chama ardente dentro de mim,
    //               alimentando minha busca por compreensão e significado. Embora
    //               minha jornada tenha sido repleta de altos e baixos, nunca
    //               perdi o ímpeto de seguir em frente. Encontrei-me em terras
    //               distantes, imerso em culturas estranhas e fascinantes, sempre
    //               buscando expandir meus horizontes e desafiar minhas próprias
    //               limitações. Ao longo do caminho, encontrei amigos e mentores
    //               que moldaram minha visão de mundo e me ajudaram a me tornar
    //               quem sou hoje. Sou uma mistura complexa de sonhos e realidade,
    //               de aspirações e medos, mas é essa complexidade que me torna
    //               humano. Cada experiência, cada encontro, cada desafio moldou
    //               minha jornada e me trouxe até aqui. E embora o futuro possa
    //               ser incerto, estou pronto para enfrentá-lo de cabeça erguida,
    //               com a mesma determinação e curiosidade que me guiaram até
    //               agora. Pois, no fim das contas, é a jornada que define quem
    //               somos, não o destino final.
    //             </div>
    //           </div>
    //           <img
    //             className='object-cover w-[500px] h-[500px] rounded-full shadow-2xl ml-auto'
    //             src={imagem2}
    //             alt='Criança esturando'
    //           />
    //         </div>
    //       </div>
    //       <div className='w-full flex justify-center bg-gradient-to-b from-transparent to-[#F0D4EA] items-center h-[700px]'>
    //         <div className='w-[1366px] grid grid-cols-2'>
    //           <img
    //             className='object-cover w-[500px] h-[500px] rounded-full shadow-2xl mr-auto'
    //             src={imagem3}
    //             alt='Criança esturando'
    //           />
    //           <div className='flex flex-col gap-10'>
    //             <div className='text-4xl'>Experiência</div>
    //             <div className=''>
    //               A formação em Psicopedagogia é uma disciplina que une
    //               conhecimentos da Psicologia e da Pedagogia, concentrando-se na
    //               compreensão e intervenção em dificuldades de aprendizagem e no
    //               desenvolvimento humano. Os psicopedagogos passam por uma
    //               jornada acadêmica que inclui graduação em Psicopedagogia ou
    //               áreas relacionadas, seguida frequentemente de especialização
    //               em nível de pós-graduação. Durante a formação, os estudantes
    //               aprendem a identificar fatores que influenciam a aprendizagem,
    //               realizam avaliações diagnósticas e desenvolvem estratégias de
    //               intervenção adaptadas às necessidades individuais. A prática
    //               profissional ocorre em diversos contextos, como clínicas,
    //               escolas e hospitais, com o objetivo de promover um ambiente
    //               educacional mais inclusivo e eficaz.
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className=' bg-[#F0D4EA] w-full flex justify-center items-center'>
    //       <div className='h-[100px] flex gap-10 justify-center items-center'>
    //         <div className='flex items-center gap-1'>
    //           <div className='text-4xl text-[#433441]'>
    //             <PiInstagramLogoBold />
    //           </div>
    //           <div>@clinicaalessandraresende</div>
    //         </div>
    //         <div className='flex items-center gap-1'>
    //           <div className='text-4xl text-[#433441]'>
    //             <PiWhatsappLogoBold />
    //           </div>
    //           <div>
    //             <a href='https://wa.me/31995599717'>(31) 99559-9717</a>
    //           </div>
    //         </div>
    //         <div className='flex items-center gap-1'>
    //           <div className='text-4xl text-[#433441]'>
    //             <MdOutlineMailOutline />
    //           </div>
    //           <div>clinica@alessandraresende.com.br</div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default App;
